

































import { Component, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TableComponent from '@common-src/mixins/table-component';
import { ThingsFunctionEntityModel } from '@common-src/entity-model/things-function-entity';
import { ViewModeType } from '@common-src/model/enum';
import { ServiceCheckManageLogEntityModel, ServiceCheckManageLogQueryModel } from '@common-src/entity-model/service-manage-entity';
import { GetServiceManageSyncLog } from '@common-src/service/service-manage';

@Component
export default class ServiceManageLogDialog extends TableComponent<ServiceCheckManageLogEntityModel, ServiceCheckManageLogQueryModel> {
    dialogVisible: boolean = false;

    dialogOpen() {
        this.dialogVisible = true;
        this.$nextTick(() => {
            this.initTable({
                listFunc: GetServiceManageSyncLog,
                queryModel: new ServiceCheckManageLogQueryModel(),
                tableColumns: ServiceCheckManageLogEntityModel.getTableColumns()
            });
            if (this.isFirstLoad) {
                this.getList();
            }
        });
    }
    async todoList() {}
    // 检查更新
    checkUpdateItem() {}
    // 同步
    async asyncItem(model: any) {}
    dialogClose(): void {
        this.dialogVisible = false;
    }

    formatStatus(text:string) {
        const item = _.find(ServiceCheckManageLogEntityModel.getServiceStatusEnum(), (item) => item.value === text) || null;
        return item;
    }
}

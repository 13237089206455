var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: "检查更新", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "things-sync-preview-dialog form-edit-component-large transform-dialog",
        },
        [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "margin-bottom": "10px" } },
            [
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("可升级驱动数：" + _vm._s(_vm.notUpdateCount)),
              ]),
              _c("span", [_vm._v("可同步驱动数：" + _vm._s(_vm.notSyncCount))]),
            ]
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              scroll: _vm.tableScroll,
              "row-key": "index",
              pagination: _vm.Pagination,
              size: _vm.TableSize,
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    record.operateOption.includes("NOT_SYNC")
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.asyncItem(record)
                              },
                            },
                          },
                          [_vm._v("同步")]
                        )
                      : _vm._e(),
                    record.operateOption.includes("NOT_UPDATE,NOT_SYNC")
                      ? _c("a-divider", { attrs: { type: "vertical" } })
                      : _vm._e(),
                    record.operateOption.includes("NOT_UPDATE")
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.update(record)
                              },
                            },
                          },
                          [_vm._v("升级")]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "localVersion",
                fn: function (text) {
                  return [
                    _c("a", { staticClass: "jtl-del-link" }, [
                      _vm._v(_vm._s(text || "-")),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c("service-manage-update-dialog", { ref: "serviceManageUpdateDialog" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { ServiceManageQueryModel, ServiceCheckManageLogQueryModel } from '@common-src/entity-model/service-manage-entity';
import { IResponseFilterData } from '@common-src/interface/reponse-data.interface';
import http from '@common-src/service3/http/axios';
import { get, post } from '@common-src/service/request';

export async function GetServiceManageSync(filter?: ServiceManageQueryModel, page?: number, pageSize?: number):Promise<IResponseFilterData<any>> {
    const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
    const res = await http.request<IResponseFilterData<any>>({
        url: `${IOT_BASE_REQUEST_PATH}/driver/sync/service/list`,
        method: 'POST',
        params
    });
    return res;
}

export async function GetServiceManageSyncCheck(filter?: any, page?: number, pageSize?: number):Promise<IResponseFilterData<any>> {
    const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
    const res = await http.request<IResponseFilterData<any>>({
        url: `${IOT_BASE_REQUEST_PATH}/driver/sync/availableUpdate/list`,
        method: 'POST',
        params
    });
    res.items = _.map(res.items, (item, index) => ({ index: index + 1, ...item }));
    return res;
}

export async function GetServiceManageSyncLog(filter?: ServiceCheckManageLogQueryModel, page?: number, pageSize?: number):Promise<IResponseFilterData<any>> {
    const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
    const res = await http.request<IResponseFilterData<any>>({
        url: `${IOT_BASE_REQUEST_PATH}/driver/sync/updateLog/list`,
        method: 'POST',
        params
    });
    res.items = _.map(res.items, (item, index) => ({ index: index + 1, ...item }));
    return res;
}

// 可升级/同步数量
async function CanUpgradableNumber():Promise<any> {
    const url = `${IOT_BASE_REQUEST_PATH}/driver/sync/availableUpdate/summary`;
    const res = await get(url);
    return res;
}
// 升级/同步
async function ToUpgradeDriver(driverId: string):Promise<any> {
    const url = `${IOT_BASE_REQUEST_PATH}/driver/sync/update/${driverId}`;
    const res = await post(url);
    return res;
}

// 驱动检查更新
async function ToCheckUpgradeDriver():Promise<any> {
    const url = `${IOT_BASE_REQUEST_PATH}/driver/sync/checkUpdate`;
    const res = await post(url);
    return res;
}

export const CommonDriverService = { CanUpgradableNumber, ToUpgradeDriver, ToCheckUpgradeDriver };

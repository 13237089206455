import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { QueryPageModel } from '@common-src/model/query-model';
import moment, { Moment } from 'moment';

const SERVICE_TYPE = [
    {
        name: '南向服务',
        value: 'SOUTH'
    },
    {
        name: '北向服务',
        value: 'NORTH'
    }
];

const SERVICE_SOURCE = [
    {
        name: '系统服务',
        value: 'SYSTEM'
    }
];

export class ServiceManageEntityModel extends BaseEntityModel {
    static getTableColumns() {
        const tableColumns = [
            {
                title: '服务名称',
                dataIndex: 'name',
                ellipsis: true
            },
            {
                title: '服务类型',
                dataIndex: 'category',
                ellipsis: true
            },
            {
                title: '服务来源',
                dataIndex: 'sourceType',
                ellipsis: true
            },
            {
                title: '当前版本',
                dataIndex: 'version',
                ellipsis: true
            },
            {
                title: '最新版本',
                dataIndex: 'cloudVersion',
                ellipsis: true
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                ellipsis: true
            },
            {
                title: '描述',
                dataIndex: 'desc',
                ellipsis: true
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
        return tableColumns;
    }
}

export class ServiceManageQueryModel extends QueryPageModel {
    @QueryControl({
        label: '服务名称',
        type: QueryControlType.TEXT,
        span: 6,
        index: 1
    })
    name: string = undefined;

    @QueryControl({
        label: '服务类型',
        type: QueryControlType.SELECT,
        options: SERVICE_TYPE,
        hasAllOption: true,
        span: 6,
        index: 2
    })
    category: string = undefined;

    @QueryControl({
        label: '服务来源',
        type: QueryControlType.SELECT,
        options: SERVICE_SOURCE,
        hasAllOption: true,
        span: 6,
        index: 3
    })
    sourceType: string = undefined;

    toService() {
        const data: any = super.getParams();
        return data;
    }
}

// 检查同步
export class ServiceCheckManageEntityModel extends BaseEntityModel {
    static getTableColumns() {
        return [
            {
                title: '服务名称',
                dataIndex: 'name',
                ellipsis: true
            },
            {
                title: '服务标识符',
                dataIndex: 'identifier',
                ellipsis: true
            },
            {
                title: '平台版本',
                dataIndex: 'cloudVersion',
                ellipsis: true
            },
            {
                title: '本地版本',
                dataIndex: 'localVersion',
                ellipsis: true,
                scopedSlots: { customRender: 'localVersion' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }
}

// 升级日志
export class ServiceCheckManageLogEntityModel extends BaseEntityModel {
    static getTableColumns() {
        return [
            {
                title: '时间',
                dataIndex: 'syncTime',
                ellipsis: true,
                customRender: (text, record, index) => {
                    return moment(text).format('YYYY-MM-DD HH:mm:ss');
                }
            },
            {
                title: '内容',
                dataIndex: 'syncDesc',
                ellipsis: true,
                customRender: (text, record, index) => {
                    return `${record.name} ${text}`;
                }
            },
            {
                title: '状态',
                dataIndex: 'upgradeStatus',
                ellipsis: true,
                scopedSlots: { customRender: 'upgradeStatus' }
            }
        ];
    }

    static getServiceStatusEnum() {
        return [{
            name: '待更新',
            value: 'AVAILABLE',
            color: ''
        },
        {
            name: '不可用',
            value: 'UNAVAILABLE',
            color: ''
        },
        {
            name: '更新中',
            value: 'UPGRADING',
            color: ''
        },
        {
            name: '更新成功',
            value: 'UPGRADED',
            color: '#1e8e3e'
        },
        {
            name: '更新失败',
            value: 'FAILURE',
            color: 'red'
        }];
    }
}
export class ServiceCheckManageLogQueryModel extends QueryPageModel {
    @QueryControl({
        label: '时间',
        type: QueryControlType.SELECT_DATE,
        span: 8,
        index: 1
    })
    dateRange: Array<Moment> = [];

    @QueryControl({
        label: '升级状态',
        type: QueryControlType.SELECT,
        options: ServiceCheckManageLogEntityModel.getServiceStatusEnum(),
        hasAllOption: true,
        span: 6,
        index: 2
    })
    upgradeStatus: boolean | string = undefined;

    toService() {
        const data: any = super.getParams();
        data.params.startTime = this.dateRange[0]?.startOf('day').toDate().getTime();
        data.params.endTime = this.dateRange[1]?.startOf('day').toDate().getTime();
        delete data.params.dateRange;
        return data;
    }
}










































import { Component, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TableComponent from '@common-src/mixins/table-component';
import { ServiceCheckManageEntityModel } from '@common-src/entity-model/service-manage-entity';
import { GetServiceManageSyncCheck, CommonDriverService } from '@common-src/service/service-manage';
import ServiceManageUpdateDialog from '@common-src/pages/admin-dashboard/service-manage/service-manage-update.dialog.vue';

@Component({
    components: {
        'service-manage-update-dialog': ServiceManageUpdateDialog
    }
})
export default class ServiceManageCheckDialog extends TableComponent<ServiceCheckManageEntityModel, any> {
    dialogVisible: boolean = false;
    notUpdateCount: number = 0; // 待升级数量
    notSyncCount: number = 0; // 待同步数量

    dialogOpen() {
        this.dialogVisible = true;
        this.$nextTick(() => {
            if (this.isFirstLoad) {
                this.initTable({
                    listFunc: GetServiceManageSyncCheck,
                    queryModel: { params: {} },
                    tableColumns: ServiceCheckManageEntityModel.getTableColumns()
                });
            }
            this.checkUpdateItem();
            this.getList();
        });

        // 打开弹窗以后，一秒和两秒的时候需要进行刷新
        setTimeout(() => {
            this.checkUpdateItem();
            this.getList();
        }, 1000);

        setTimeout(() => {
            this.checkUpdateItem();
            this.getList();
        }, 2000);
    }
    async todoList() {}

    // 检查更新
    checkUpdateItem() {
        CommonDriverService.CanUpgradableNumber().then((res) => {
            this.notUpdateCount = res.notUpdateCount;
            this.notSyncCount = res.notSyncCount;
        }).catch();
    }

    update(model:any) {
        (this.$refs.serviceManageUpdateDialog as ServiceManageUpdateDialog).dialogOpen(model.localVersion, model.cloudVersion, model.driverId);
    }
    // 同步
    async asyncItem(model: any) {
        CommonDriverService.ToUpgradeDriver(model.driverId).then(() => {
            this.showMessageSuccess('同步成功！');
            this.getList();
        }).catch(() => {
            this.showMessageSuccess('同步失败！');
        });
    }
    dialogClose(): void {
        this.dialogVisible = false;
    }
}

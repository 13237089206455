var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("服务管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { staticClass: "table-query-card", attrs: { title: "查询" } },
            [
              _c("jtl-table-query-component", {
                ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                on: { search: _vm.searchClick },
              }),
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { title: "列表" } },
            [
              _c(
                "div",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra" },
                  slot: "extra",
                },
                [
                  _c(
                    "jtl-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.upgradeLog },
                    },
                    [_vm._v("升级日志")]
                  ),
                  _c(
                    "jtl-button",
                    {
                      attrs: { "table-header-child": "", type: "primary" },
                      on: { click: _vm.checkUpdate },
                    },
                    [_vm._v("检查更新(" + _vm._s(_vm.notUpdateCount) + ")")]
                  ),
                ],
                1
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": "id",
                  size: _vm.TableSize,
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.upgrade(record)
                              },
                            },
                          },
                          [_vm._v("升级")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("service-manage-check-dialog", { ref: "serviceManageCheckDialog" }),
      _c("service-manage-log-dialog", { ref: "serviceManageLogDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
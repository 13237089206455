var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-small",
      attrs: { title: "驱动升级", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _vm.updateProcess === 1
        ? _c("div", { staticClass: "update-dialog-panel" }, [
            _c("div", [_vm._v("请确认是否升级")]),
            _c("div", [_vm._v("当前边缘端版本：" + _vm._s(_vm.localVersion))]),
            _c("div", [_vm._v("最新版本：" + _vm._s(_vm.cloudVersion))]),
          ])
        : _vm._e(),
      _vm.updateProcess === 2
        ? _c("div", { staticClass: "update-dialog-panel" }, [
            _c("div", { staticClass: "update-dialog-panel-loading" }, [
              _vm._v(" 升级中，请稍后...... "),
            ]),
          ])
        : _vm._e(),
      _vm.updateProcess === 3
        ? _c(
            "div",
            { staticClass: "update-dialog-panel" },
            [
              _c("a-result", {
                attrs: {
                  status: "success",
                  title: "升级成功",
                  "sub-title":
                    "当前边缘端已是最新版本(" + _vm.cloudVersion + ")",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.updateProcess === 4
        ? _c(
            "div",
            { staticClass: "update-dialog-panel" },
            [
              _c("a-result", {
                attrs: { status: "error", "sub-title": "升级失败，请重试！" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.updateProcess !== 2
        ? _c(
            "template",
            { slot: "footer" },
            [
              _vm.updateProcess !== 3
                ? _c(
                    "jtl-button",
                    { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _c(
                "jtl-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmClick } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.updateProcess === 4 ? "重试" : "确认") +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }










































import { Component, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TableComponent from '@common-src/mixins/table-component';
import { ServiceCheckManageEntityModel } from '@common-src/entity-model/service-manage-entity';
import { GetServiceManageSyncCheck, CommonDriverService } from '@common-src/service/service-manage';

@Component
export default class ServiceManageUpdateDialog extends TableComponent<ServiceCheckManageEntityModel, any> {
    dialogVisible: boolean = false;
    updateProcess: number = 1;
    localVersion: string = ''; // 当前版本
    cloudVersion: string = ''; // 最新版本
    driveId: string = '';

    dialogOpen(localVersion:string, cloudVersion:string, id:string) {
        this.dialogVisible = true;
        this.localVersion = localVersion;
        this.cloudVersion = cloudVersion;
        this.driveId = id;
    }

    // 同步
    async asyncItem(model: any) {
        CommonDriverService.ToUpgradeDriver(model.driverId).then(() => {
            this.showMessageSuccess('同步成功！');
            this.getList();
        }).catch(() => {
            this.showMessageSuccess('同步失败！');
        });
    }
    dialogClose(): void {
        this.dialogVisible = false;
        this.updateProcess = 1;
    }

    updateProcessFunc() {
        this.updateProcess = 2;
        CommonDriverService.ToUpgradeDriver(this.driveId).then(() => {
            this.updateProcess = 3;
        }).catch(() => {
            this.updateProcess = 4;
        });
    }

    confirmClick() {
        if (this.updateProcess === 1 || this.updateProcess === 4) {
            this.updateProcessFunc();
        }

        if (this.updateProcess === 3) {
            this.dialogClose();
        }
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: "升级日志", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "things-sync-preview-dialog form-edit-component-large transform-dialog",
        },
        [
          _c(
            "div",
            { staticClass: "table-query-card" },
            [
              _c("jtl-table-query-component", {
                ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                on: { search: _vm.searchClick },
              }),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              scroll: _vm.tableScroll,
              "row-key": "index",
              pagination: _vm.Pagination,
              size: _vm.TableSize,
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u([
              {
                key: "upgradeStatus",
                fn: function (text) {
                  return [
                    _c(
                      "div",
                      {
                        style: {
                          color: _vm.formatStatus(text)
                            ? _vm.formatStatus(text).color
                            : "",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatStatus(text)
                                ? _vm.formatStatus(text).name
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
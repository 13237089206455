








































import { Component } from 'vue-property-decorator';
import Tableomponent from '@common-src/mixins/table-component';
import { ServiceManageEntityModel, ServiceManageQueryModel } from '@common-src/entity-model/service-manage-entity.ts';
import { GetServiceManageSync, CommonDriverService } from '@common-src/service/service-manage';
import { UserStoreModule } from '@common-src/store/modules/user';
import ServiceManageCheckDialog from '@common-src/pages/admin-dashboard/service-manage/service-manage-check.dialog.vue';
import ServiceManageLogDialog from '@common-src/pages/admin-dashboard/service-manage/service-manage-log.dialog.vue';

@Component({
    components: {
        'service-manage-check-dialog': ServiceManageCheckDialog,
        'service-manage-log-dialog': ServiceManageLogDialog
    }
})
export default class ServiceManageListComponent extends Tableomponent<ServiceManageEntityModel, ServiceManageQueryModel> {
    showCheck: boolean = false;
    notUpdateCount: number = 0;
    created() {
        this.initTable({
            listFunc: GetServiceManageSync,
            queryModel: new ServiceManageQueryModel(),
            tableColumns: ServiceManageEntityModel.getTableColumns()
        });
        this.getList();
        this.checkUpdateItem();
    }

    // 检查更新数量统计
    checkUpdateItem() {
        CommonDriverService.CanUpgradableNumber().then((res) => {
            this.notUpdateCount = res.notUpdateCount;
        }).catch();
    }
    // 检查更新
    checkUpdate() {
        CommonDriverService.ToCheckUpgradeDriver();
        (this.$refs.serviceManageCheckDialog as ServiceManageCheckDialog).dialogOpen();
    }
    // 升级日志
    upgradeLog() {
        (this.$refs.serviceManageLogDialog as ServiceManageLogDialog).dialogOpen();
    }
    upgrade() {
        this.showMessageSuccess('升级驱动成功');
    }
}

